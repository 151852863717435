import * as React from "react"
import clsx from "clsx"
import Layout from "../../components/Layout"
import Seo from "../../components/Seo"
import BrandMarkColour from "../../images/brand-mark-colour.svg"
import BrandMarkConstruction from "../../images/brand-mark-construction.svg"
import BrandMarkUsage from "../../images/brand-mark-usage.svg"
import BrandMarkFormats from "../../images/brand-mark-formats.svg"
import DNAVideo from "../../videos/dna-small.mp4"
import Captions from "file-loader!../../a11y/brand.vtt"

const BrandPage = () => {

    const colours = [
      {
        name: 'red',
        jit: 'bg-[#d00000]',
        hex: '#d00000',
        rgb: 'rgb(208, 0, 0)',
        cmyk: '	c:0 m:100, y:100 k:18'
      },
      {
        name: 'purple',
        jit: 'bg-[#8f53c3]',
        hex: '#8f53c3',
        rgb: 'rgb(143, 83, 195)',
        cmyk: 'c:27 m:57 y:0 k:26'
      },
      {
        name: 'white',
        jit: 'bg-[#fff]',
        hex: '#fff',
        rgb: 'rgb(255, 255, 255)',
        cmyk: 'c:0 m:0 y:0 k:0'
      },
      {
        name: 'charcoal',
        jit: 'bg-[#2a2f3d]',
        hex: '#2a2f3d',
        rgb: 'rgb(42, 47, 61)',
        cmyk: 'c:31 m:23 y:0 k:76'
      }
    ];

    return (
    <Layout>
      <Seo 
        title="Brand"
        description="Brand and Usage guidelines."
      />
      <div className="mx-4 max-w-screen lg:max-w-screen-md lg:mx-auto"> 
        <h1 className="text-3xl font-bold text-anomaly-red">Brand and usage guidelines</h1>
        <div className="p-6 my-10 text-xl bg-white rounded-xl">
          <p className="">Anomaly &mdash; <a className="font-bold" href="https://www.oxforddictionaries.com/definition/english/anomaly"><i>noun</i></a></p>
          <p className="text-gray-700">"Something that deviates from what is standard, normal, or expected."</p>
        </div>
        <p className="p-6 mb-10">
          Anomaly was created as a vehicle for delivering beautiful digital products, powered by cutting-edge technologies. 
          Our work is ambitious, obsessive, bound by perfection and truly unique. The brand is designed to reflect this.
        </p>
        <video className="border-4 border-gray-600" controls>
          <source src={DNAVideo} type="video/mp4" />
          <track kind="captions" srcLang="en" src={Captions} />
        </video>
        <div className="flex flex-col items-center justify-between py-2 mx-auto text-gray-600 lg:flex-row">
          <p>Produced by <a className="font-bold" href="https://blackholemilkbar.com">Black Hole Milk Bar</a></p>
          <p>Timelapse by <a className="font-bold" href="https://theartofnight.com/">Mark Gee</a></p>
          <p>Original music by <a className="font-bold" href="https://www.danphillipson.com/">Dan Phillipson</a></p>
        </div>

        <section>
          <h2 className="py-4 text-2xl font-bold text-anomaly-red">The Mark</h2>
          <p>
          The mark is composed of three concentric circles divisible by a single number. 
          The central ring is broken, with one component juxtaposing the rest; symbolising Anomaly's alternate approach to product design.
          </p>
        </section>

        <section>
          <h2 className="py-4 text-2xl font-bold text-anomaly-red">Construction</h2>
          <BrandMarkConstruction className="w-full mx-4 my-8 lg:mx-auto"/>
        </section>

        <section>
          <h2 className="py-4 text-2xl font-bold text-anomaly-red">Usage</h2>
          <p>
            The mark renders well in any strong colour so long as there is ample contrast between the background and itself.
          </p>
          <BrandMarkColour className="w-full mx-4 my-8 lg:mx-auto"/>
          <p>
            Unless otherwise permitted, the mark <strong>must</strong> be presented in one of the brand colours listed 
            below or rendered in solid white or black, against a brand colour.
          </p>
          <p>
            Equally as important as colour is legibility. The mark <strong>must</strong> be rendered at an appropriate size, 
            with ample white space. <strong>Do not</strong> rotate, squash or distort the mark in any way.
          </p>
          <BrandMarkUsage className="w-full mx-4 my-8 lg:mx-auto"/>

        </section>

        <section>
          <h2 className="py-4 text-2xl font-bold text-anomaly-red">Formats</h2>
          <p>
            When the logo is presented by itself, unless otherwise permitted, it <strong>must</strong> be accompanied by 
            its wordmark. The wordmark <strong>must not</strong> be detached from the picturemark and cannot be distorted in any way.
          </p>
          <BrandMarkFormats className="w-full mx-4 my-8 lg:mx-auto"/>
        </section>

        <section>
          <h2 className="py-4 text-2xl font-bold text-anomaly-red">Colours</h2>
          <p>Anomaly is best represented by two primary colours as well as white and black. The mark can be used in 
            any of these colours so long as the contrast is well-defined, as above.</p>
          <ul className="my-4">
          {colours.map((colour) => {
            return(
              <li key={colour.name} className="flex items-center mb-2">
                <div className={clsx(
                  'h-6 w-6 border-2', 
                  colour.jit)}>
                  </div>
                <span className="ml-4 font-mono">{colour.hex}, {colour.rgb}, {colour.cmyk}</span>
              </li>
            );
          })}
          </ul>
        </section>

        <section>
          <h2 className="py-4 text-2xl font-bold text-anomaly-red">Typography</h2>
          <p>
            The Anomaly wordmark is set in <a href="https://myfonts.com/fonts/daltonmaag/aller-typo/">Aller Regular</a> and has been manually kerned.
            Anomaly web content assumes the operating system font of the device it is being viewed on, where possible. In all other cases, content is set in 
            the following font stack; <b>Helvetica Neue, Helvetica, Arial, sans-serif</b> and should be rendered using one of the brand colours above.
          </p>
        </section>

        <section>
          <h2 className="py-4 text-2xl font-bold text-anomaly-red">Imagery</h2>
          <p>
            The imagery surrounding any representation of Anomaly's brand is important. 
            Themes of space, minimalism and technology are generally preferred. 
            Use of our brand in any religious or political context is <b>strictly prohibited.</b> 
          </p>
        </section>

        <section>
          <h2 className="py-4 text-2xl font-bold text-anomaly-red">Downloads</h2>
          <p>
            By downloading and using Anomaly branding material, you agree to abide by the brand guidelines outlined on this page.
          </p>
          <a 
              className="flex flex-row justify-center px-20 py-3 mx-auto mt-8 border-2 rounded-md cursor-pointer max-w-fit text-anomaly-red border-anomaly-red hover:text-white hover:bg-anomaly-red" 
              href="/downloads/Anomaly-Logo.zip">
                  Download
                  <svg xmlns="http://www.w3.org/2000/svg" className="w-6 h-6 ml-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17 13l-5 5m0 0l-5-5m5 5V6" />
                  </svg> 
         </a>

        </section>
      </div>
    </Layout>
   );
};
export default BrandPage
